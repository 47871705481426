import type { DOMNode, HTMLReactParserOptions } from 'html-react-parser';

import parse, { domToReact, Element } from 'html-react-parser';
import React, { useCallback, useMemo } from 'react';
import dynamic from 'next/dynamic';

import {
  Logo,
  TextHighlight as HighlightComponent,
  Button,
} from '@leafwell/components';
import BrazeContentCard from './braze-card';
import Image from 'next/image';
import DownloadablesForm from 'components/downloadables-form';
import PartnerCustomData from './partner-custom-data';

import { useEventsTracker } from 'contexts/eventsTracker';

const MedicalCardCta = dynamic(() => import('./medical-card-cta'));
const CannabisCounselingCta = dynamic(
  () => import('./cannabis-counseling-cta'),
);

export type TextHighlightState = JSX.Element;

const TextHighlight: React.FC<{ text: string }> = ({ text }) => {
  const { trackEvent } = useEventsTracker();

  const isElement = useCallback((domNode: DOMNode): domNode is Element => {
    const isTag = domNode.type === 'tag';
    const hasAttributes = (domNode as Element).attribs !== undefined;

    return isTag && hasAttributes;
  }, []);

  const parserOptions: HTMLReactParserOptions = useMemo(
    () => ({
      trim: true,
      replace: domNode => {
        if (isElement(domNode) && domNode?.name === 'img') {
          const { src, alt, class: className, width, height } = domNode.attribs;
          const isHbsptCta =
            className?.length > 0 ? className.includes('hs-cta-img') : false;
          const isContentImg =
            className?.length > 0 ? className.includes('content-img') : false;

          return (
            <Image
              src={src}
              alt={alt}
              width={Number(width)}
              height={Number(height)}
              sizes={isHbsptCta || isContentImg ? '100vw' : '50vw'}
              className={[className].join(' ')}
            />
          );
        }
        if (isElement(domNode) && domNode?.attribs?.['data-braze'] === 'form') {
          const formType = domNode?.attribs?.['data-braze-form-type'];

          return formType ? <DownloadablesForm formType={formType} /> : null;
        }

        if (isElement(domNode) && domNode?.attribs?.['data-partner']) {
          return <PartnerCustomData />;
        }

        if (isElement(domNode) && domNode?.attribs?.['data-highlight']) {
          switch (domNode.attribs?.['data-highlight']) {
            case 'circle':
              return (
                <HighlightComponent variant="circle">
                  {domToReact(domNode.children, parserOptions)}
                </HighlightComponent>
              );
            case 'underline':
              return (
                <HighlightComponent>
                  {domToReact(domNode.children, parserOptions)}
                </HighlightComponent>
              );
            case 'rec-card':
              return (
                <MedicalCardCta
                  title={domNode.attribs?.['data-title']}
                  buttonLabel={domNode.attribs?.['data-button']}
                  description={domNode.attribs?.['data-description']}
                />
              );
            case 'cc-cta':
              return (
                <CannabisCounselingCta
                  {...{
                    title: domNode.attribs?.['data-title'],
                    description: domNode.attribs?.['data-description'],
                    isBlogPost: true,
                    variant: 'external',
                  }}
                />
              );
            case 'braze-card':
              return (
                <BrazeContentCard
                  {...{
                    brazeId: domNode.attribs?.['data-id'],
                    isBlogPost: true,
                  }}
                />
              );
            case 'leaf':
            case 'logo':
              return (
                <Logo
                  variant="icon"
                  width={25}
                  classNameButton="align-bottom mx-1"
                  aria-hidden="true"
                  href={null}
                />
              );
            case 'button':
              return (
                <span className="flex justify-center mb-6">
                  <Button
                    label={domNode.attribs?.['data-label']}
                    href={domNode.attribs?.['data-ref']}
                    rounded={true}
                    target={domNode.attribs?.['data-target'] || '_self'}
                    variant="secondary"
                    onClick={() => {
                      trackEvent({
                        event: 'select_content',
                        content_type: domNode.attribs?.['data-label'],
                        item_id: domNode.attribs?.['data-ref'],
                        origin: window?.location?.href,
                      });
                    }}
                  />
                </span>
              );
          }
        }
      },
    }),
    [isElement],
  );

  return useMemo(() => {
    if (text?.length > 0) {
      return <>{parse(text, parserOptions)}</>;
    }

    return <></>;
  }, [parserOptions, text]);
};

export default TextHighlight;
