import type { CaptionedImage, ClassicCard } from '@braze/web-sdk';
import React, { useEffect, useState } from 'react';
import { Wrap, Button, Card, Paragraph } from '@leafwell/components';
import Figure from './figure';
import HeadingBody from './heading-body';
import { useBrazeContext } from 'contexts/braze-context';

type ContentCard = CaptionedImage | ClassicCard;

function CardImage({ sourceUrl, className }) {
  return (
    <Figure
      src={sourceUrl}
      className={['rounded-2xl sm:order-last', className].join(' ')}
      classNameImage="!h-full sm:object-cover"
      alt={''}
      width={0}
      height={0}
    />
  );
}

function CardContent({ title, description }) {
  return (
    <>
      <HeadingBody title={title} level="2" tagName="h2" className="!text-3xl" />
      <Paragraph>{description}</Paragraph>
    </>
  );
}

function CardWrap({ wrapComponent: Component, className, children }) {
  return (
    <Component className={['grid not-prose', className].join(' ')}>
      {children}
    </Component>
  );
}

const BrazeContentCard: React.FC<{
  brazeId: string;
  isBlogPost?: boolean;
}> = ({ brazeId, isBlogPost = false }) => {
  const [contentCards, setContentCards] = useState<ContentCard[] | null>(null);
  const { hasBrazeInitialized, fetchContentCards, requestContentCardsRefresh } =
    useBrazeContext();

  useEffect(() => {
    if (!hasBrazeInitialized) {
      return;
    }

    function getContentCards() {
      setTimeout(() => {
        requestContentCardsRefresh();
      }, 1_000);
    }

    // There's a bug on getting and subscribing to content cards updates so we need to this for now
    // issue: https://github.com/braze-inc/braze-web-sdk/issues/151
    fetchContentCards(function (cards) {
      if (cards.length) {
        setContentCards(cards as ContentCard[]);
      } else {
        getContentCards();
      }
    });

    getContentCards();
  }, [hasBrazeInitialized]);

  if (!contentCards) {
    return null;
  }

  const cardObj = contentCards?.find(card => card.extras.ID === brazeId);

  if (!cardObj) {
    return null;
  }

  const button = cardObj.url
    ? {
        url: cardObj.url,
        title: cardObj.linkText ? cardObj.linkText : 'Click here',
      }
    : false;
  const hasImage = !!cardObj.imageUrl;

  if (isBlogPost) {
    return (
      <CardWrap
        wrapComponent="div"
        className={[
          'mt-12 mb-6',
          hasImage ? 'sm:grid-cols-3 sm:gap-x-2.5 gap-y-2.5' : '',
        ].join(' ')}
      >
        {hasImage ? (
          <CardImage sourceUrl={cardObj.imageUrl} className={'md:max-h-full'} />
        ) : null}
        <Card
          rounded={true}
          filled={true}
          variant="light"
          className={hasImage ? 'sm:col-span-2' : ''}
        >
          <div className="grid gap-3">
            <CardContent
              title={cardObj.title}
              description={cardObj.description}
            />
            {button ? (
              <Button
                {...{
                  href: button.url,
                  label: button.title,
                  target: 'blank',
                  rounded: true,
                  variant: 'light',
                  className: 'w-fit h-fit self-end',
                }}
              />
            ) : null}
          </div>
        </Card>
      </CardWrap>
    );
  } else {
    return (
      <CardWrap
        wrapComponent={Wrap}
        className={hasImage ? 'sm:grid-cols-3 sm:gap-x-2.5 gap-y-2.5' : ''}
      >
        {hasImage ? (
          <CardImage sourceUrl={cardObj.imageUrl} className={'md:max-h-full'} />
        ) : null}
        <Card
          rounded={true}
          filled={true}
          variant="light"
          className={hasImage ? 'sm:col-span-2' : ''}
        >
          <div
            className={['grid gap-3', !hasImage ? 'sm:grid-cols-3' : ''].join(
              ' ',
            )}
          >
            <div
              className={['grid gap-3', !hasImage ? 'sm:col-span-2' : ''].join(
                ' ',
              )}
            >
              <CardContent
                title={cardObj.title}
                description={cardObj.description}
              />
            </div>
            {button ? (
              <Button
                {...{
                  href: button.url,
                  label: button.title,
                  target: 'blank',
                  rounded: true,
                  variant: 'secondary',
                  filled: true,
                  className: `w-fit h-fit self-end ${
                    !hasImage ? 'sm:justify-self-end' : ''
                  }`,
                }}
              />
            ) : null}
          </div>
        </Card>
      </CardWrap>
    );
  }
};

export default BrazeContentCard;
